"use client";

import { useEffect, useRef } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";

import { useUpdateShowNavMobile } from "@/store/update-show-nav-mobile";
import { MobileNavExploreIcon } from "../../assets/mobile-nav-explore-icon";
import { MobileNavBookingsIcon } from "../../assets/mobile-nav-bookings-icon";
import { MobileNavProfileIcon } from "../../assets/mobile-nav-profile-icon";
import { OffersIcon } from "../../assets/offers-icon";

interface NavType {
  text: string;
  link: string;
  icon: (props: { color: string }) => JSX.Element;
}

const DATA: NavType[] = [
  { text: "explore", link: "/", icon: MobileNavExploreIcon },
  { text: "offers", link: "/offers", icon: OffersIcon },
  { text: "bookings", link: "/bookings", icon: MobileNavBookingsIcon },
  { text: "profile", link: "/profile", icon: MobileNavProfileIcon },
];

// Tweak these as you like

export const BottomNavBar = ({
  mobileSearchBar,
}: {
  mobileSearchBar?: boolean;
}) => {
  const {
    setShowBottomNav,
    showBottomNav,
    setShowHeaderNav,
    setShadowSearchBar,
  } = useUpdateShowNavMobile((state) => state);
  const pathname = usePathname();
  const isListingsRoute = pathname.startsWith("/listings");

  const TOP_ZONE = isListingsRoute ? 10 : 300; // We always show nav if scrollY <= 300
  const SCROLL_THRESHOLD = 40; // We only toggle after user has scrolled 40px in one direction
  // Add a buffer zone to prevent toggling when near TOP_ZONE boundary
  const BUFFER_ZONE = isListingsRoute ? 10 : 30; // Buffer zone below TOP_ZONE

  // Last scroll Y position
  const lastScrollYRef = useRef(0);

  // Current scroll direction (1 = down, -1 = up, 0 = unknown)
  const scrollDirectionRef = useRef<0 | 1 | -1>(0);

  // How many px we have scrolled so far in the current direction
  const accumulatedRef = useRef(0);

  // Last toggle state timestamp to prevent rapid toggling
  const lastToggleTimeRef = useRef(0);

  useEffect(() => {
    // Setup initial conditions on mount
    const initialY = window.scrollY;
    lastScrollYRef.current = initialY;

    // Decide initial show/hide based on initial scroll position
    if (initialY <= TOP_ZONE) {
      setShowBottomNav(true);
      setShowHeaderNav(true);
    } else {
      setShowBottomNav(false);
      setShowHeaderNav(false);
    }

    const handleScroll = () => {
      const currentY = window.scrollY;
      const lastY = lastScrollYRef.current;
      setShadowSearchBar(false);

      if (!isListingsRoute) {
        if (currentY >= 4350) {
          setShowBottomNav(true);
          setShowHeaderNav(true);
          setShadowSearchBar(true);
          return;
        }
      }

      // --- 1) If we are near the top, ALWAYS show, no toggles. ---
      if (currentY <= TOP_ZONE) {
        setShowBottomNav(true);
        setShowHeaderNav(true);

        // Reset direction so we don't build up weird deltas right near the top
        scrollDirectionRef.current = 0;
        accumulatedRef.current = 0;
        lastScrollYRef.current = currentY;
        return;
      }

      // --- 2) Handle the buffer zone just below TOP_ZONE to prevent rapid toggles ---
      if (currentY <= TOP_ZONE + BUFFER_ZONE) {
        // In the buffer zone, we don't change the nav state
        // Just update the last position and return
        lastScrollYRef.current = currentY;
        return;
      }

      // --- 3) If we just crossed from buffer zone to normal scroll zone, reset tracking ---
      if (
        lastY <= TOP_ZONE + BUFFER_ZONE &&
        currentY > TOP_ZONE + BUFFER_ZONE
      ) {
        lastScrollYRef.current = currentY;
        scrollDirectionRef.current = 0;
        accumulatedRef.current = 0;
        return;
      }

      // --- 4) We are firmly below buffer zone, so apply normal "scroll direction" logic. ---
      const delta = currentY - lastY;

      // Ignore very small movements that could be device noise
      if (Math.abs(delta) < 2) {
        lastScrollYRef.current = currentY;
        return;
      }

      const absDelta = Math.abs(delta);

      // Figure out direction (1 = down, -1 = up)
      const newDirection = delta > 0 ? 1 : -1;
      const oldDirection = scrollDirectionRef.current;

      // If the direction changed, reset the accumulated distance
      if (newDirection !== oldDirection) {
        scrollDirectionRef.current = newDirection;
        accumulatedRef.current = 0;
      }

      // Accumulate how far we've scrolled in this direction
      accumulatedRef.current += absDelta;

      // Prevent toggling too frequently (at least 300ms between toggles)
      const now = Date.now();
      const timeSinceLastToggle = now - lastToggleTimeRef.current;

      // If we've accumulated enough distance and enough time has passed, toggle
      if (
        accumulatedRef.current >= SCROLL_THRESHOLD &&
        timeSinceLastToggle > 300
      ) {
        if (newDirection === 1) {
          // User scrolled down a lot => hide nav
          setShowBottomNav(false);
          setShowHeaderNav(false);
        } else {
          // User scrolled up a lot => show nav
          setShowBottomNav(true);
          setShowHeaderNav(true);
        }

        // Reset accumulator after toggling and update time
        accumulatedRef.current = 0;
        lastToggleTimeRef.current = now;
      }

      // Always update our last Y
      lastScrollYRef.current = currentY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    setShowBottomNav,
    setShowHeaderNav,
    BUFFER_ZONE,
    TOP_ZONE,
    isListingsRoute,
    setShadowSearchBar,
  ]);

  if (mobileSearchBar) return null;

  return (
    <nav
      style={{
        boxShadow: "0px -6px 8px 0px rgba(165, 142, 116, 0.20)",
        transition: "all 30ms",
      }}
      className={`bg-white hidden fixed bottom-0 left-0 z-40 w-screen mobile:flex items-center justify-center px-5
        ${showBottomNav ? "h-[67px]" : "h-0"}
      `}
    >
      <div
        className={`w-full h-full items-center justify-between ${
          showBottomNav ? "flex" : "hidden"
        }`}
      >
        {DATA.map(({ text, link, icon }) => (
          <NavLink text={text} link={link} icon={icon} key={text} />
        ))}
      </div>
    </nav>
  );
};

const NavLink = ({ text, link, icon }: NavType) => {
  const pathName = usePathname();
  const isActive = text === "explore" ? pathName === "/" : pathName === link;

  return (
    <Link
      href={link}
      className="flex flex-col items-center justify-center gap-y-[5px]"
    >
      {icon({ color: isActive ? "#51311F" : "#D5CABD" })}
      <span
        className={`text-sm ${isActive ? "text-primaryB7" : "text-neutralN6"}`}
      >
        {text}
      </span>
    </Link>
  );
};
