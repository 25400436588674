interface Props {
  color: string;
}

export const MobileNavProfileIcon = ({ color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2C6.73603 2 2.25 6.48604 2.25 12C2.25 17.514 6.73603 22 12.25 22C17.764 22 22.25 17.514 22.25 12C22.25 6.48604 17.764 2 12.25 2ZM12.25 3.5C16.9533 3.5 20.75 7.2967 20.75 12C20.75 14.2281 19.891 16.2459 18.4941 17.7598C17.3008 15.5255 14.9547 14 12.25 14C9.54527 14 7.19916 15.5255 6.00586 17.7598C4.60902 16.2459 3.75 14.2281 3.75 12C3.75 7.2967 7.54669 3.5 12.25 3.5ZM12.25 7C11.145 7 10.25 7.895 10.25 9V10C10.25 11.105 11.145 12 12.25 12C13.355 12 14.25 11.105 14.25 10V9C14.25 7.895 13.355 7 12.25 7ZM12.25 15.5C14.5273 15.5 16.4497 16.8658 17.3242 18.8125C15.908 19.8681 14.1569 20.5 12.25 20.5C10.3431 20.5 8.59202 19.8681 7.17578 18.8125C8.05027 16.8658 9.97268 15.5 12.25 15.5Z"
        fill={color}
      />
    </svg>
  );
};
