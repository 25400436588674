"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { LabelText } from "../common/texts/label-text";

interface Props {
  path: string;
  children: React.ReactNode;
}

export const DropdownLink = ({ path, children }: Props) => {
  const pathname = usePathname();

  return (
    <Link
      href={path}
      className={`flex items-center gap-x-2 p-2 border-b-[0.5px] border-neutralN5`}
    >
      <LabelText className="text-neutralN8 !leading-[12px] !flex items-center gap-x-2">
        {children}
      </LabelText>
    </Link>
  );
};
