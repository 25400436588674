"use client";

import { SearchBar } from "@/app/(single)/_components/header/search-bar";
import { useUpdateSearchScroll } from "@/store/update-search-scroll";

export const HeaderSearch = () => {
  const { isScrolled } = useUpdateSearchScroll((state) => state);

  return (
    <div
      style={{ transition: "all 500ms" }}
      className={isScrolled ? "opacity-100" : "opacity-0"}
    >
      <SearchBar />
    </div>
  );
};
