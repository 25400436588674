interface Props {
  color: string;
}

export const MobileNavBookingsIcon = ({ color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2C6.73603 2 2.25 6.48604 2.25 12C2.25 17.514 6.73603 22 12.25 22C17.764 22 22.25 17.514 22.25 12C22.25 6.48604 17.764 2 12.25 2ZM12.25 3.5C16.9533 3.5 20.75 7.2967 20.75 12C20.75 16.7033 16.9533 20.5 12.25 20.5C7.54669 20.5 3.75 16.7033 3.75 12C3.75 7.2967 7.54669 3.5 12.25 3.5ZM16.4023 7.05859C16.3276 7.06879 16.2532 7.09311 16.1777 7.13086L10.5215 9.95703C10.3855 10.025 10.275 10.1355 10.207 10.2715L7.38086 15.9277C7.07886 16.5317 7.71827 17.1721 8.32227 16.8691L13.9785 14.043C14.1145 13.975 14.225 13.8645 14.293 13.7285L17.1191 8.07422C17.3843 7.54484 16.9255 6.98724 16.4023 7.05859ZM12.25 11C12.802 11 13.25 11.448 13.25 12C13.25 12.552 12.802 13 12.25 13C11.698 13 11.25 12.552 11.25 12C11.25 11.448 11.698 11 12.25 11Z"
        fill={color}
      />
    </svg>
  );
};
