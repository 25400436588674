interface Props {
  color: string;
}

export const MobileNavExploreIcon = ({ color }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.5906 0 0 3.59061 0 8C0 12.4094 3.5906 16 8 16C9.92974 16 11.6358 15.2251 13.0195 14.082L18.7188 19.7812C18.7885 19.851 18.8713 19.9064 18.9625 19.9441C19.0536 19.9819 19.1513 20.0014 19.25 20.0014C19.3487 20.0014 19.4464 19.9819 19.5376 19.9442C19.6287 19.9064 19.7115 19.8511 19.7813 19.7813C19.8511 19.7115 19.9064 19.6287 19.9442 19.5376C19.9819 19.4464 20.0014 19.3487 20.0014 19.25C20.0014 19.1513 19.9819 19.0536 19.9441 18.9625C19.9064 18.8713 19.851 18.7885 19.7812 18.7188L14.082 13.0195C15.2251 11.6358 16 9.92974 16 8C16 3.59061 12.4094 0 8 0ZM8 1.5C11.5987 1.5 14.5 4.40127 14.5 8C14.5 11.5987 11.5987 14.5 8 14.5C4.40126 14.5 1.5 11.5987 1.5 8C1.5 4.40127 4.40126 1.5 8 1.5Z"
        fill={color}
      />
    </svg>
  );
};
