"use client";
import { logout } from "../../../actions/logout";
import toast from "react-hot-toast";
import { LogOutIcon } from "../../../assets/log-out-icon";
import { LabelText } from "../common/texts/label-text";

export const LogoutButton = () => {
  const handleLogout = () => {
    const isLogout = logout();
    if (isLogout.status === "error") {
      toast.error(isLogout.message);
      return;
    }

    toast.success("Logged out successfully");
  };

  return (
    <button onClick={handleLogout} className="flex items-center gap-x-2 p-2">
      <LogOutIcon color="#65605A" />
      <LabelText className="text-neutralN8 !flex items-center gap-x-2">
        Log Out
      </LabelText>
    </button>
  );
};
